<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :tabs="tabs" :back="$route.meta.back" :readonly="$authorised('con/schedule/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body v-if="!is.loading">

		<app-content-summary v-if="!isNew">

			<app-content-summary-item label="Status" :tag="(item.cancelled) ? 'Cancelled' : 'Scheduled'" :status="item.cancelled ? 2 : 1" />
			<app-content-summary-item v-if="hasGame" label="Host" :value="item.host.name" />
			<app-content-summary-item label="Time" :value="item.date | formatDate('dddd, h:mma')" />
			<app-content-summary-item label="Location" :grow="true" :value="item.location" />
			<app-content-summary-item :label="(model.ticket) ? 'Tickets available' : 'Seats available'" :value="item.seats.available + ' / ' + item.seats.total" />
			
		</app-content-summary>

		<app-content-box :readonly="$authorised('con/schedule/access', 'read')">

			<app-input-toggle label="Official" v-model="model.official" :validation="$v.model.official" />
			<app-input-toggle label="Enable Clashing" v-model="model.clashes.enabled" :validation="$v.model.clashes.enabled" />
			<app-input-toggle label="Cancelled" v-model="model.cancelled" :validation="$v.model.cancelled" />
			<app-input-select label="Ticketed" v-model="model.ticket" :allow-deselect="true" placeholder="Not ticketed" :validation="$v.model.ticket" :options="references.tickets" />
			<app-input-select label="Type" placeholder="Select type" v-model="model.type" :validation="$v.model.type" :options="typeOptions" />
			<app-input-suggest v-if="hasGame" label="Game" placeholder="Select game" image="image" v-model="model.game" api="bgg/game" :validation="$v.model.game" />
			<app-input-text label="Name" placeholder="Enter name..." v-model="model.name" :validation="$v.model.name" />
			<app-input-text label="Description" :autogrow="true" placeholder="Enter description..." v-model="model.description" :validation="$v.model.description" />
			<app-input-image  label="Image" v-model="model.image" :validation="$v.model.image" />
			<app-input-toggle label="Min. Age" :slim="true" v-model="model.age" :validation="$v.model.age" :options="ageOptions" />
			<app-input-toggle v-if="hasGame" label="Min. Experience" :slim="true" v-model="model.experience" :validation="$v.model.experience" :options="expOptions" :tooltips="expTooltips" />
			<app-input-toggle label="Teacher Wanted" v-model="model.teacher" :validation="$v.model.teacher" v-if="hasGame" />
			<app-input-checklist label="Lobbies" v-model="model.lobbies" :validation="$v.model.lobbies" :options="lobbyOptions" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/schedule/access', 'read')" icon="buddies" :title="(model.ticket) ? 'Tickets' : 'Seats'">

			<app-input-toggle v-if="hasGame" label="Host playing" :options="hostOptions" v-model="model.seats.host" :validation="$v.model.seats.host" />
			<app-input-text :label="(model.ticket) ? 'Total' : 'Total seats'" placeholder="Enter number..." v-model="model.seats.total" :validation="$v.model.seats.total" />
			<app-input-text v-if="hasGame" label="Blocked seats" placeholder="Enter number..." v-model="model.seats.blocked" :validation="$v.model.seats.blocked" />
			
		</app-content-box>

		<app-content-box :readonly="$authorised('con/schedule/access', 'read')" icon="schedule" title="Time">

			<app-input-toggle label="Day" v-model="model.day" :validation="$v.model.day" :options="dayOptions" :fullWidth="true" />
			<app-input-select label="Time" v-model="model.time" :validation="$v.model.time" :options="timeOptions" placeholder="Select time" />
			<app-input-select label="Duration" v-model="model.duration" :validation="$v.model.duration" :options="durationOptions" placeholder="Select duration" />
			
		</app-content-box>

		<app-content-box :readonly="$authorised('con/schedule/access', 'read')" icon="location" title="Location">

			<app-input-toggle label="Assign later" v-model="model.location.skip" />
			<app-input-toggle v-if="!model.location.skip" label="Location" :options="locationTypeOptions" v-model="model.location.custom" :validation="$v.model.location.custom" />
			<app-input-text v-if="!model.location.skip && model.location.custom" label="Directions" :autogrow="true" v-model="model.location.directions" :validation="$v.model.location.directions" />
			<app-input-select v-if="!model.location.skip && !model.location.custom" label="Room" v-model="model.location.room" :options="roomOptions" :asString="true" placeholder="Select room" />
			<app-input-select v-if="!model.location.skip && model.location.room && !model.location.custom" label="Table" v-model="model.location.space" placeholder="Select space" :options="spaceOptions" :asString="true" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/schedule/access', 'read'),
			references: {
				tickets: [],
				rooms: [],
				spaces: [],
				types: [],
				lobbies: [],
				days: [],
				times: [],
				durations: []
			},
			hostOptions: {
				0: 'Yes',
				1: 'No'
			},
			locationTypeOptions: {
				0: 'Official table',
				1: 'Other space'
			},
			model: {
				id: false,
				official: 0,
				cancelled: 0,
				name: '',
				host: 0,
				ticket: 0,
				teacher: 0,
				game: 0,
				type: 0,
				lobbies: [],
				experience: 0,
				age: 0,
				description: '',
				image: '',
				clashes: {
					enabled: 1
				},
				seats: {
					host: 0,
					total: 0,
					blocked: 0
				},
				day: 0,
				time: 0,
				duration: 0,
				location: {
					skip: 0,
					room: 0,
					space: 0,
					custom: 0,
					directions: ''
				}
			}
		}

	},

	validations: {
		model: {
			name: {
				required
			},
			seats: {},
			location: {},
			clashes: {}
		}
	},

	computed: {

		selectedType: function() {

			return (this.model.type) ? this.$_.findWhere(this.references.types, {
				id: this.model.type
			}) : false

		},

		hasGame: function() {

			return (this.selectedType) ? this.selectedType.games.enabled : true

		},

		ageOptions: function() {

			return this.$constants.schedule.ageShort

		},

		expOptions: function() {

			return this.$constants.schedule.experienceShort

		},

		expTooltips: function() {

			return this.$constants.schedule.experienceTooltip

		},

		tabs: function() {

			return [
				{ name: 'Convention.Schedule.Events.Edit', text: 'Setup' },
				{ name: 'Convention.Schedule.Event.Seats', text: (this.model.ticket) ? 'Tickets' : 'Seats', params: { event: this.item.id } },
				{ name: 'Convention.Schedule.Event.Messages', text: 'Messages', params: { event: this.item.id } }
			]

		},

		typeOptions: function() {

			var options = {}

			this.$_.each(this.references.types, function(type) {

				options[type.id] = type.name

			})

			return options

		},

		timeOptions: function() {

			return this.references.times

		},

		dayOptions: function() {

			return this.references.days

		},

		durationOptions: function() {

			return this.references.durations

		},

		lobbyOptions: function() {

			var options = {}

			this.$_.each(this.references.lobbies, function(type) {

				options[type.id] = type.name

			})

			return options

		},

		roomOptions: function() {

			var options = {}

			this.$_.each(this.references.rooms, function(type) {

				options[type.id] = type.name

			})

			return options

		},

		spaceOptions: function() {

			var options = {}

			this.$_.each(this.references.spaces, function(type) {

				if (type.room === this.model.location.room) {

					options[type.id] = type.name

				}

			}.bind(this))

			return options

		}

	}

}

</script>

<style scoped>

</style>